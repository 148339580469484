import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { DASHBOARD_ROUTE } from '@/src/constants/routes';

const Index = () => {
  const { query, push } = useRouter();
  useEffect(() => {
    const queryString = query?.oap ? `?oap=${query.oap}` : '';
    push(`${DASHBOARD_ROUTE}${queryString}`);
  }, [query?.oap, push]);
  return null;
};

export default Index;
